import React, { useEffect, useState } from "react";
import { Link } from "@reach/router";
import './post.css'

const Post = ({ id }) => {
  const [post, setPost] = useState({});

  /* Get individual post based on ID from backend */
  useEffect(() => {
    const getPost = async () => {
      const resp = await fetch(
        `https://general.alexzhang1618.workers.dev/api/posts/${id}`
      );
      const postResp = await resp.json();
      setPost(postResp);
    };

    getPost();
  }, [id]);

  /* If post doesn't exist in the backend, render an error page */
  if (!Object.keys(post).length) 
    return (<div className="body" >
                <h1 className="header">Oops! There doesn't seem to be a post here.</h1>
                <p>
                    <Link to="/">Back to home</Link>
                </p>
            </div>
    );

  /* Render the specific page for the post */
  return (
    <div className="body" >
      <div className="title">
        <h1 className="header">{post.title} - published {post.published_at}</h1>
        <h2 className="username">posted by {post.username}</h2>
      </div>
      <div className="paragraphs" >
        <p>{post.content}</p>
      </div>
      <p>
        <em>Published {new Date(post.published_at).toLocaleString()}</em>
      </p>
      <p>
          <Link to="/">Back to home</Link>
      </p>
    </div>
  );
};

export default Post;