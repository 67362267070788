import React, { useEffect, useState } from "react";
import './form.css'

class Form extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
          title: '',
          content: '',
          username: ''
        };
  
      this.handleTitle = this.handleTitle.bind(this);
      this.handleContent = this.handleContent.bind(this);
      this.handleUsername = this.handleUsername.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
    }
  
    handleTitle(event) {
      this.setState({title: event.target.value});
    }
  
    handleContent(event) {
      this.setState({content: event.target.value});
    }

    handleUsername(event) {
        this.setState({username: event.target.value});
    }
    
    /* On submission, take in input from form and send a POST request
       to the backend to save a new post in the KV namespace. */
    handleSubmit(event) {
      event.preventDefault();
      const resp = fetch("https://general.alexzhang1618.workers.dev/api/posts", {
        mode: "no-cors",
        method: 'POST', 
        body: JSON.stringify(this.state), 
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-type': 'application/json'
        }
      });
      alert("Post submitted!");
      /* Clear fields after submitting */
      this.setState({
        title: '',
        content: '',
        username: ''
      });
    }
  
    /* Render the post submission form and store values from each field. */
    render() {
      return (
        <div className = "form-box">
            <h5 className = "form-title">Submit a new post:</h5>
            <form id="postForm" onSubmit={this.handleSubmit}>
            <div className = "field">
                <input placeholder="Title" type="text" value={this.state.title} onChange={this.handleTitle} />
                <input placeholder="Username" type="text" value={this.state.username} onChange={this.handleUsername} />
                <textarea placeholder="Enter text here" type="text" value={this.state.content} onChange={this.handleContent} />
                <input type="submit" value="Submit" />
            </div>
            </form>
        </div>
      );
    }
  }

export default Form